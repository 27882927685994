import { APIFilter } from '@/utils/api'

export default {
  async selectSobreDestinatarioFaltan (Vue, filter, search, sorter, page, idsobre) {
    const apiFilter = new APIFilter()
      .addExact('idsobre', idsobre)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.sobreDestinatario.searchFilter(search))
    }
    const resp = await Vue.$api.call(
      'sobreDestinatario.selectDestinatariosFaltan', {
        page,
        filter: apiFilter,
        sorter,
      },
    )
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectSobreDestinatarioFaltanRows (Vue, idsobre) {
    const apiFilter = new APIFilter()
      .addExact('idsobre', idsobre)
    const resp = await Vue.$api.call('sobreDestinatario.selectDestinatariosFaltan', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
