import { basePageMixin } from '@/mixins/basePageMixin'
import _ from '@/utils/lodash'
import { currentScrollPosition } from '@/utils/ui'
import { beforeRouteLeaveCommon } from './common'

export const listPageMixin = {
  mixins: [basePageMixin],
  data () {
    return {
      showingDialogs: {
        listItemOptions: false,
        filter: false,
        sorter: false,
      },
      currentListItem: null,
      selecting: false,
      selectedFieldname: '_selected',
    }
  },
  computed: {
    selectedItems () {
      return _.filter(this.items, (item) => { return item[this.selectedFieldname] === true })
    },
    filterEmpty () {
      const hasValue = _.filter(this.filter, (item) => {
        return !!item.value
      })
      return hasValue.length === 0
    },
  },
  methods: {
    async initStore () {
      await this.copyRouteParamsToPageStore()
      // parámetros especiales:
      // * _search: búsqueda inicial
      // * _filter: filtro inicial
      // * _show_filter: mostrar filtro inicial al entrar
      if (this.pageStoreName) {
        if ('_filter' in this.routeParams) {
          for (const field in this.routeParams._filter) {
            await this.setStoreProperty(`filter@${field}.value`, this.routeParams._filter[field].value)
            if (this.routeParams._filter[field].options) {
              await this.setStoreProperty(`filter@${field}.options`, this.routeParams._filter[field].options)
            }
          }
        }
        if ('_search' in this.routeParams) {
          await this.setStoreProperty('search', this.routeParams._search)
        }
        if ('_show_filter' in this.routeParams && this.filterEmpty) {
          this.$nextTick(() => { this.showingDialogs.filter = true })
        }
      }
    },
    async loadItemsBase (dataset, metadata) {
      if (metadata && metadata.sorter_desc && metadata.sorter) {
        await this.setStoreProperty('sorterDesc', metadata.sorter_desc)
        await this.setStoreProperty('sorter', metadata.sorter)
      } else {
        await this.setStoreProperty('sorterDesc', [])
        await this.setStoreProperty('sorter', [])
      }
      await this.setStoreProperty('showLoadMore', dataset.length >= this.$api.ITEMS_PER_PAGE)
      await this.setStoreProperty('currentPage', this.currentPage + 1)
      for (let item of dataset) {
        this.setListItemDefaultProperties(item)
      }
      await this.setStoreProperty('items', this.items.concat(dataset))
    },
    async reloadItemsBase (modifiedDataset, dirty, fieldname) {
      for (const modified of dirty.modified) {
        const itemIndex = _.findIndex(this.items, (item) => {
          return modified === _.resolveProperty(fieldname, item)
        })
        if (itemIndex > -1) {
          const modifiedIndex = _.findIndex(modifiedDataset, (item) => {
            return modified === _.resolveProperty(fieldname, item)
          })
          if (modifiedIndex > -1) {
            // recargar modificación
            await this.setStoreProperty(`items@[${itemIndex}]`, modifiedDataset[modifiedIndex])
            this.setListItemDefaultProperties(this.items[itemIndex])
          } else {
            // el item en cache ya no existe
            await this.dispatchStore('deleteItem', itemIndex)
          }
        }
      }
      for (const id of dirty.deleted) {
        const itemIndex = _.findIndex(this.items, [fieldname, id])
        if (itemIndex > -1) {
          await this.dispatchStore('deleteItem', itemIndex)
        }
      }
    },
    async beforeRouteLeaveBase (to, from, next) {
      if (!beforeRouteLeaveCommon(this, to, from, next)) {
        if (this.rememberScroll) {
          await this.setStoreProperty('scrollPosition', currentScrollPosition())
        } else {
          await this.dispatchStore('resetScroll')
        }
        if (!this.rememberState) {
          await this.dispatchStore('resetStore')
        }
        next()
      }
    },
    async clearSelection () {
      this.selecting = false
      await this.dispatchStore('clearSelection', { selectedFieldname: this.selectedFieldname })
    },
    async resetList () {
      await this.dispatchStore('resetList')
      await this.dispatchStore('resetScroll')
      // loadItems de la lista donde se aplica el mixin
      this.loadItems()
    },
    setListItemDefaultProperties (item) {
      this.$set(item, this.selectedFieldname, false)
    },
  }
}
