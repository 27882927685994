import md5 from 'md5'

export const formatBase64 = (item) => {
  item = item.split(',').pop()
  const missingPadding = item.length % 4
  if (missingPadding !== 0) {
    item += '='.repeat(4 - missingPadding)
  }
  return item
}

export const calculateMd5 = (str) => {
  return md5(str)
}
